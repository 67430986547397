.select-box .select-box__check {
  display: block;
  width: 100%;
  height: 55px;
  padding: 0;
  margin: 0;
}

.select-box input.form-check-input {
  width: 100%;
  height: 55px;
  border-radius: 0.5rem;
  background-image: none;
  float: none;
  margin: 0;
}

.select-box .form-check-input:hover {
  cursor: pointer;
}

.select-box input.form-check-input:checked {
  background-image: none;
}

.select-box .form-check-label {
  width: 100%;
  height: 100%;
  text-align: center;
}

.select-box .form-check-input:checked + .form-check-label {
  color: white;
}
