.sd-radio,
.sd-checkbox {
  border: 1px solid lightgrey;
  margin-top: 15px;
  border-radius: 5px;
  padding: 0;
}

.sd-radio--checked,
.sd-checkbox--checked {
  background: #19b394;

  .sd-item__control-label {
    color: white;
  }
}

.sd-radio .sd-selectbase__label,
.sd-checkbox .sd-selectbase__label {
  margin: 0;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.sd-radio .sd-item__decorator,
.sd-checkbox .sd-item__decorator {
  display: none;
}

.sd-radio .sd-item__control-label,
.sd-checkbox .sd-item__control-label {
  text-align: center;
}

.sd-progress-buttons .sd-progress-buttons__list .sd-progress-buttons__button-background,
.sd-progress-buttons .sd-progress-buttons__list .sd-progress-buttons__button-content {
  display: none;
}
