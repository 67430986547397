.svgMap-map-wrapper {
  position: relative;
  width: 100%;
  height: 300px;
  padding-top: 0;
  overflow: hidden;
  background: $white;
  color: $white;

  * {
    box-sizing: border-box;
  }

  :focus:not(:focus-visible) {
    outline: 0;
  }

  .svgMap-map-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  // Controls

  .svgMap-map-controls-wrapper {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 1;
    display: flex;
    overflow: hidden;
    @include box-shadow($box-shadow-sm);
    @include border-radius($border-radius);
  }

  .svgMap-map-controls-zoom,
  .svgMap-map-controls-move {
    display: flex;
    margin-right: 5px;
    overflow: hidden;
    background: $gray-900;

    &:last-child {
      margin-right: 0;
    }
  }

  .svgMap-control-button {
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: relative;

    &.svgMap-zoom-button {
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $gray-300;
        transition: background-color 0.2s;
      }

      &:before {
        width: 11px;
        height: 3px;
      }

      &:hover {
        &:before,
        &:after {
          background: $white;
        }
      }

      &.svgMap-disabled {
        &:before,
        &:after {
          background: $gray-400;
        }
      }
    }

    &.svgMap-zoom-in-button {
      margin: 1px 0 1px 1px;

      &:after {
        width: 3px;
        height: 11px;
      }
    }

    &.svgMap-zoom-out-button {
      margin: 1px 1px 1px 0;
    }
  }

  // Countries

  .svgMap-country {
    cursor: pointer;
    stroke: $white;
    stroke-width: 1;
    stroke-linejoin: round;
    vector-effect: non-scaling-stroke;
    transition:
      fill 0.2s,
      stroke 0.2s;

    &:hover,
    &.svgMap-active {
      stroke: $red;
    }

    &.svgMap-active {
      stroke-width: $red;
    }
  }
}

//Tooltip
.svgMap-tooltip {
  position: absolute;
  z-index: 2;
  background: $card-bg;
  transform: translate(-50%, -100%);
  display: none;
  pointer-events: none;
  min-width: 60px;
  border: $border-width solid $border-color;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  @include box-shadow($box-shadow-sm);
  @include border-radius($border-radius);

  &.svgMap-tooltip-flipped {
    transform: translate(-50%, 0);
    border-bottom: 0;
    border-top: 1px solid #000;
  }

  &.svgMap-active {
    display: block;
  }

  .svgMap-tooltip-content-container {
    position: relative;
    padding: 10px 20px;

    .svgMap-tooltip-flag-container {
      text-align: left;
      margin: 2px 0 5px;
      &.svgMap-tooltip-flag-container-emoji {
        font-size: 40px;
        line-height: 0;
        padding: 25px 0 15px;
      }

      .svgMap-tooltip-flag {
        display: block;
        width: auto;
        height: 35px;
        border: 2px solid #ffffff;
        @include border-radius($border-radius);
      }
    }
  }

  .svgMap-tooltip-title {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    white-space: nowrap;
    padding: 0 0 8px;
    text-align: left;
  }

  .svgMap-tooltip-content {
    white-space: nowrap;
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    color: $gray-300;
    table {
      padding: 0;
      border-spacing: 0px;

      td {
        padding: 2px 0;
        text-align: left;
        font-weight: $font-weight-normal;
        span {
          color: $success;
          font-weight: $font-weight-bold;
        }

        &:first-child {
          padding-right: 15px;
          text-align: left;
          color: $gray-700;
        }

        sup {
          vertical-align: baseline;
          position: relative;
          top: -5px;
        }
      }
    }

    .svgMap-tooltip-no-data {
      padding: 2px 0;
      color: $gray-300;
      font-style: italic;
    }
  }

  .svgMap-tooltip-pointer {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    height: 10px;
    width: 30px;

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      background: $card-bg;
      border: $border-width solid $border-color;
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
  }

  &.svgMap-tooltip-flipped {
    .svgMap-tooltip-pointer {
      bottom: auto;
      top: -10px;
      transform: translateX(-50%) scaleY(-1);
    }
  }
}
