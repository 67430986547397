/**
 * = Avatars
 */

.avatar + .avatar-content {
  display: inline-block;
  margin-left: 0.75rem;
}

.avatar-sm {
  width: $avatar-sm-y;
  height: $avatar-sm-x;

  img {
    width: $avatar-sm-y;
    height: $avatar-sm-x;
  }
}

.avatar {
  width: $avatar-y;
  height: $avatar-x;

  img {
    width: $avatar-y;
    height: $avatar-x;
  }
}

.avatar-md {
  width: $avatar-md-y;
  height: $avatar-md-x;

  img {
    width: $avatar-md-y;
    height: $avatar-md-x;
  }
}

.avatar-lg {
  width: $avatar-lg-y;
  height: $avatar-lg-x;

  img {
    width: $avatar-lg-y;
    height: $avatar-lg-x;
  }
}

.avatar-xl {
  width: $avatar-xl-y;
  height: $avatar-xl-x;

  @include media-breakpoint-down(lg) {
    width: $avatar-lg-y;
    height: $avatar-lg-x;
  }
}

.avatar-offline,
.avatar-online {
  &::before {
    position: absolute;
    border: 2px solid $white;
    background-color: $success;
    display: block;
    top: 2px;
    left: 39px;
    content: '';
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
}

.avatar-offline {
  &::before {
    background-color: $danger;
  }
}

// Overlapped avatars
.avatar-group {
  .avatar {
    position: relative;
    z-index: 0;

    img {
      border: 2px solid $white;
    }

    &:hover {
      z-index: 3;
    }
  }

  .avatar + .avatar {
    margin-left: -1rem;
  }
}
