/*
* = Buttons
*/

.btn {
  &.btn-circle {
    border-radius: $circle-radius;
  }
  &.btn-md {
    padding: 0.65rem 1.25rem;
  }
  &.btn-xs {
    padding: 0.2rem 0.35rem;
    font-size: 0.55rem;
    box-shadow: none;
  }
  &.large-form-btn {
    padding: 1.2rem 1rem;
  }
  &.dashed-outline {
    border-style: dashed;
  }
}

.btn-icon-only {
  width: 2.575rem;
  height: 2.575rem;
  padding: 0;
  @include display-flex();
  @include justify-content(center);
  @include align-items(center);

  &.btn-xs {
    width: 1.7rem;
    height: 1.7rem;
  }
  &.btn-sm {
    width: 2rem;
    height: 2rem;
  }
}

// Fix for darken background color
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    &:hover {
      background-color: darken($value, 5%);
      border-color: darken($value, 5%);
    }
  }
}

// Brand (social) buttons
@each $color, $value in $brand-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);

    &.btn-link {
      color: $value;
      background: transparent;
      box-shadow: none;
      border: 0;
      border-style: none;
      &:hover,
      &:focus,
      &.active {
        background-color: transparent !important;
        box-shadow: none;
        border: 0;
        color: $value;
      }
    }
  }
}

//Modal Close Buttons
.theme-settings-close {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 0.65rem;
}

.btn-close {
  box-sizing: content-box;
  width: $btn-close-width;
  height: $btn-close-height;
  padding: $btn-close-padding-y $btn-close-padding-x;
  color: $btn-close-color;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat; // include transparent for button elements
  border: 0; // for button elements
  @include border-radius();
  opacity: $btn-close-opacity;

  // Override <a>'s hover style
  &:hover {
    color: $btn-close-color;
    text-decoration: none;
    opacity: $btn-close-hover-opacity;
  }

  &:focus {
    outline: none;
    box-shadow: $btn-close-focus-shadow;
    opacity: $btn-close-focus-opacity;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: $btn-close-disabled-opacity;
  }
}

.btn-close-white {
  filter: $btn-close-white-filter;
}

.copy-code-button {
  position: absolute;
  top: 20px;
  right: 25px;
  background-color: transparent;
  color: $gray-600;
  border: 0;
  box-shadow: none;

  &:hover {
    background-color: $primary;
    color: $white;
    border: 0;
    box-shadow: none;
  }
}

.copy-code-text {
  font-size: medium;
  position: absolute;
  top: 24px;
  right: 90px;
}

@include media-breakpoint-down(md) {
  .prism-code {
    padding-top: 2.5rem !important;
  }
}
