// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
  .accordion-button {
    background-color: transparent;
    &:not(.collapsed) {
      color: $tertiary;
    }
    &:focus {
      border-color: $gray-300;
      box-shadow: none;
    }
  }
  .accordion-collapse {
    border: 0;
  }
}
