.image-input {
  position: relative;
  min-height: 200px;
  padding: 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;

  background-color: #f2f4f6;
  background-image: repeating-linear-gradient(
      45deg,
      #f9fafb 25%,
      transparent 25%,
      transparent 75%,
      #f9fafb 75%,
      #f9fafb
    ),
    repeating-linear-gradient(45deg, #f9fafb 25%, #f2f4f6 25%, #f2f4f6 75%, #f9fafb 75%, #f9fafb);
  background-position:
    0 0,
    25px 25px;
  background-size: 50px 50px;

  label {
    width: 100%;
    text-align: center;
    color: #262b40;
    transition: color 0.3s;
    cursor: pointer;
  }

  &__hidden {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }

  &__preview-list {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &--focused {
      & > .image-preview {
        z-index: 1;
      }
    }
  }
}

.image-preview {
  position: relative;
  overflow: hidden;
  padding: 0 5px;
  cursor: pointer;

  &__element {
    height: min(500px, 40vh);
    max-width: min(400px, 40vw);
    object-fit: cover;
    background: white;
  }

  &__actions {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #11182722;
    margin: 0 5px;
    opacity: 0;
    transition: opacity 0.3s;

    button {
      margin: 0 0.1rem;
    }
  }

  &:hover {
    overflow: visible;

    .image-preview__element {
      object-fit: contain;
      max-width: min(600px, 50vw);
    }

    .image-preview__actions {
      opacity: 1;
    }
  }
}
