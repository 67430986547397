iframe {
  border: 0;
}

figcaption,
figure,
main {
  display: block;
  margin: 0;
}

main {
  overflow: hidden;
}

img {
  max-width: 100%;
}

strong {
  font-weight: $font-weight-bold;
}

button:focus {
  outline: 0;
}

label {
  font-weight: $font-weight-bold;
  margin-bottom: 0.5rem;
}
