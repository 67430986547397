.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(84.18deg, #2d495d 0.31%, #28333a 100%);
  overflow: scroll;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
    transform-origin: center center;
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    transform-origin: center center;
  }
}

@keyframes translate {
  0% {
    transform: translateX(0);
    transform-origin: center center;
  }
  25% {
    transform: translateX(50px);
    transform-origin: center center;
  }
  50% {
    transform: translateX(0px);
    transform-origin: center center;
  }
  75% {
    transform: translateX(-50px);
    transform-origin: center center;
  }
  100% {
    transform: translateX(0px);
    transform-origin: center center;
  }
}

.imgg path:first-child {
  animation: heartbeat 2s ease-in-out infinite;
}

.imgg path:nth-child(2) {
  animation: translate 2s linear infinite;
}
.imgg {
  margin-left: 40px;
  width: 30vw;
  height: 30vw;
}

.brand {
  margin-bottom: 5rem;
  font-size: 4vw;
  font-family: 'Yeseva One', cursive;
  color: white;
  display: inline !important;
}

.brandIn {
  color: #cdaa64;
  display: inline !important;
}
.gradient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(84.18deg, #2d495d 0.31%, #28333a 100%);
}
@media (max-width: 1000px) {
  .imgg {
    margin-left: 20px;
    margin-bottom: 20px;
    width: 50vw;
    height: 50vw;
  }
  .brand {
    font-size: 7vw;
  }
}
