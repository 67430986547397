$pattern-color-1: #f9fafb;
$pattern-color-2: #f2f4f6;
$tools-width: 400px;

.image-editor {
  display: flex;
  flex-direction: column;
  height: min(1000px, 75vh);

  &__canvas {
    flex-grow: 1;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;

    background-color: $pattern-color-1;
    background-image: repeating-linear-gradient(
        45deg,
        $pattern-color-2 25%,
        transparent 25%,
        transparent 75%,
        $pattern-color-2 75%,
        $pattern-color-2
      ),
      repeating-linear-gradient(
        45deg,
        $pattern-color-2 25%,
        $pattern-color-1 25%,
        $pattern-color-1 75%,
        $pattern-color-2 75%,
        $pattern-color-2
      );
    background-position:
      0 0,
      10px 10px;
    background-size: 20px 20px;
  }

  &__tools {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: $tools-width;
    margin: auto;

    .btn-group {
      margin-top: 5px;
    }
  }

  &__circle-icon {
    margin: auto;
    border-radius: 50%;
    background: white;
    border: 1px solid #d1d5db;
  }
}
