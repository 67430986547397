// =========================================================
// * Volt Pro React Dashboard
// =========================================================

// * Product Page: https://themesberg.com/product/dashboard/volt-pro-react
// * Copyright 2021 Themesberg (https://www.themesberg.com)
// * License Information (https://themesberg.com/licensing)

// * Designed and coded by https://themesberg.com

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. Please contact us to request a removal.

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

// update variables here
@import 'custom/variables';

@import '../../node_modules/bootstrap/scss/functions';
@import 'volt/variables';

// Bootstrap
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/utilities';

// Modify utilities here
@import 'volt/utilities';

// Bootstrap Layout & components
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/containers';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/button-group';
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/navbar';
@import '../../node_modules/bootstrap/scss/card';
@import '../../node_modules/bootstrap/scss/accordion';
@import '../../node_modules/bootstrap/scss/breadcrumb';
@import '../../node_modules/bootstrap/scss/pagination';
@import '../../node_modules/bootstrap/scss/badge';
@import '../../node_modules/bootstrap/scss/alert';
@import '../../node_modules/bootstrap/scss/progress';
@import '../../node_modules/bootstrap/scss/list-group';
@import '../../node_modules/bootstrap/scss/close';
@import '../../node_modules/bootstrap/scss/toasts';
@import '../../node_modules/bootstrap/scss/modal';
@import '../../node_modules/bootstrap/scss/tooltip';
@import '../../node_modules/bootstrap/scss/popover';
@import '../../node_modules/bootstrap/scss/carousel';
@import '../../node_modules/bootstrap/scss/spinners';

// Bootstrap helpers & utlities
@import '../../node_modules/bootstrap/scss/helpers';
@import '../../node_modules/bootstrap/scss/utilities/api';

// volt mixins & functions
@import 'volt/mixins';
@import 'volt/functions';

// Layout
@import 'volt/layout';

// Components
@import 'volt/components';

// Forms
@import 'volt/forms';

// Custom overrides
@import 'custom/tables';
